// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-cohort-two-js": () => import("/opt/build/repo/src/pages/cohort-two.js" /* webpackChunkName: "component---src-pages-cohort-two-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-events-gallery-js": () => import("/opt/build/repo/src/pages/eventsGallery.js" /* webpackChunkName: "component---src-pages-events-gallery-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-posters-gallery-js": () => import("/opt/build/repo/src/pages/postersGallery.js" /* webpackChunkName: "component---src-pages-posters-gallery-js" */)
}

